.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pill-button {
  background-color: white; /* White background */
  border: 1px solid black; /* Thin black border */
  color: black; /* Black text */
  padding: 11px 22px; /* Adjust padding as needed */
  font-size: medium; /* Adjust font size as needed */
  font-family: 'Inter', sans-serif; /* Inter font */
  font-weight: 400; /* Semi-bold text */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px; /* Adjust border-radius for rounded corners */
  /* animation: fadeIn 1s ease-in-out 1s forwards; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@keyframes retroPress {
  0% {
    transform: scale(1);
    box-shadow: 0 4px #666;
  }
  50% {
    transform: scale(0.92);
    box-shadow: 0 1px #666;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px #666;
  }
}

.pill-button:active {
  animation: retroPress 0.2s ease;
}
